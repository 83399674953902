import { Injectable } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { ICredentials } from '@aws-amplify/core';
import { Observable, defer } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthPayload, AuthSession, AuthUser } from '../models/auth.model';
import { AuthGateway } from '../usecases/auth.gateway';

@Injectable()
export class AuthService extends AuthGateway {
  currentAuthenticatedUser(): Observable<AuthUser> {
    return defer(() => Auth.currentAuthenticatedUser());
  }

  currentSession(): Observable<AuthSession> {
    return defer(() => Auth.currentSession()).pipe(
      map(session => ({ token: session.getIdToken().getJwtToken(), payload: session.getIdToken().payload as AuthPayload })),
    );
  }

  signIn(): Observable<ICredentials | void> {
    return defer(() => Auth.federatedSignIn({ customProvider: 'Okta-SAML' }));
  }
}
