import { Observable } from 'rxjs';

import { AuthPayload, AuthState } from '../models/auth.model';

export abstract class AuthUsecase {
  abstract get authState$(): Observable<AuthState>;
  abstract get token$(): Observable<string>;
  abstract get payload$(): Observable<AuthPayload>;

  abstract signIn(): Observable<never>;
  abstract setAuthState(state: AuthState): void;
}
