import { ICredentials } from '@aws-amplify/core';
import { Observable } from 'rxjs';

import { AuthSession, AuthUser } from '../models/auth.model';

export abstract class AuthGateway {
  abstract currentAuthenticatedUser(): Observable<AuthUser>;
  abstract currentSession(): Observable<AuthSession>;
  abstract signIn(): Observable<ICredentials | void>;
}
