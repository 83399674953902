export const appConfig = {
  region: 'ap-northeast-1',
  identityPoolId: 'ap-northeast-1:3a3b0fc2-ac38-4c42-8989-32b618808bc5',
  userPoolId: 'ap-northeast-1_GlwzzRiDM',
  userPoolWebClientId: '2dla7a9as034dmsd3tui3kfh6r',
  oktaIdentityPoolId: 'ap-northeast-1:c42a6f8a-af73-40df-8924-13579ab05c03',
  oktaUserPoolId: 'ap-northeast-1_y0MEyWqKU',
  oktaUserPoolDomain: 'develop-test-chem-dxone',
  oktaUserPoolWebClientId: '3du94hck0aaosin8re21qoevkm',
  envName: 'develop',
  apiUrl: 'https://tms.develop.test.chem-dxone.io',
  wssUrl: 'wss://tms.develop.test.chem-dxone.io/wss',
};
