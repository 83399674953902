import { Injectable } from '@angular/core';

import { DatadogGateway } from '../usecases/datadog.gateway';

@Injectable()
export class DatadogService extends DatadogGateway {
  setUser(id?: string, organization?: string): void {
    if (id) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).DD_RUM?.setUser({ id, organization });
    }
  }
}
