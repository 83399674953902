import { Observable } from 'rxjs';

import { User, UserCreateParams, UserOrganizationUpdateParams, UserUpdateParams, Users } from '../models/user.model';

export abstract class UserUsecase {
  abstract get users$(): Observable<Users>;

  abstract createUser(params: UserCreateParams): Observable<User>;
  abstract updateUser(userId: string, params: UserUpdateParams): Observable<never>;
  abstract deleteUser(userId: string): Observable<never>;
  abstract updateUserOrganization(userId: string, params: UserOrganizationUpdateParams): Observable<never>;

  abstract reload(): void;
}
