import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { User, UserCreateParams, UserOrganizationUpdateParams, UserQueryParams, UserUpdateParams } from '../models/user.model';
import { ListResponse } from '../models/utility.model';
import { UserGateway } from '../usecases/user.gateway';

@Injectable()
export class UserService extends UserGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listUsers(params: UserQueryParams): Observable<ListResponse<User>> {
    return this._http.get<ListResponse<User>>('api/com/users', { params });
  }

  createUser(params: UserCreateParams): Observable<User> {
    return this._http.post<User>('api/com/users', params);
  }

  updateUser(userId: string, params: UserUpdateParams): Observable<User> {
    return this._http.put<User>(`api/com/users/${userId}`, params);
  }

  deleteUser(userId: string): Observable<void> {
    return this._http.delete<void>(`api/com/users/${userId}`);
  }

  updateUserOrganization(userId: string, params: UserOrganizationUpdateParams): Observable<User> {
    return this._http.put<User>(`api/com/users/${userId}/organization`, params);
  }
}
